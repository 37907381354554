<template>
  <section>
    <div class="box">
      <h1 class="title is-4">Filtros de busqueda</h1>
      <div class="field" v-if="$store.getters.hasAnyRole(['empleador'])">
        <label class="label">
          Mis recibos
          <span class="is-pulled-right">
            <b-tooltip label="Opción para empleador" position="is-left">
              <i class="far fa-star is-pulled-right"></i>
            </b-tooltip>
          </span>
        </label>
        <b-checkbox v-model="soloMisRecibos" :disabled="selectedUser">Ver solo mis recibos</b-checkbox>
      </div>
       <div class="field" v-if="$store.getters.hasAnyRole(['empleador'])" v-show="!soloMisRecibos" >
        <label class="label">
          Por empleado
          <span class="is-pulled-right">
            <b-tooltip label="Opción para empleador" position="is-left">
              <i class="far fa-star is-pulled-right"></i>
            </b-tooltip>
          </span>
        </label>
        <b-autocomplete v-model="userInput" :data="searchUserResults" placeholder="Escriba nombre o apellido" v-if="!selectedUser" field="apellido" :loading="isFetching" @input="getUser" @select="option => selectedUser = option">
          <template slot-scope="props">
            <i class="fas fa-user"></i> {{ props.option.apellido.toUpperCase() }}, {{ props.option.nombre }}
            <br>
            <span class="is-size-7">{{props.option.cuil}} - {{props.option.email}}</span>
          </template>
          <template slot="empty">No hay resultados para {{userInput}}</template>
        </b-autocomplete>
      </div>
      <div class="field" v-if="selectedUser">
        <p><a @click.prevent="selectedUser = userInput = null"><i class="fas fa-times-circle"></i></a>&nbsp;{{ selectedUser.apellido.toUpperCase() }}, {{ selectedUser.nombre }}</p>
      </div>
      <div class="field">
        <label class="label">
          Por fecha
        </label>
        <div class="help">El campo en blanco representa cualquier dia, mes o año</div>
      </div>
      <div class="field is-horizontal is-mobile">
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input has-text-centered" name="año" v-model="year" placeholder="Año" v-validate="'min:4|max:4|min_value:1920|numeric'" :class="{'is-danger': errors.has('año')}">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input has-text-centered" name="mes" v-model="month" :disabled="year == '' || errors.has('año')" placeholder="Mes" v-validate="'max:2|min_value:1|max_value:12|numeric'" :class="{'is-danger': errors.has('mes')}">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input has-text-centered" name="dia" v-model="day" :disabled=" month == '' || errors.has('mes')" placeholder="Dia" v-validate="'max:2|min_value:1|max_value:31|numeric'" :class="{'is-danger': errors.has('dia')}">
            </div>
          </div>
        </div>
      </div>
      <span class="help is-danger" v-if="errors.has('año')">{{ errors.first('año') }}</span>
      <span class="help is-danger" v-if="errors.has('mes')">{{ errors.first('mes') }}</span>
      <span class="help is-danger" v-if="errors.has('dia')">{{ errors.first('dia') }}</span>
      <div class="field">
        <label for="" class="label">Por tipo de recibo</label>
        <div class="control">
          <input type="text" class="input" value="- Cargando -" v-if="loadingTiposRecibos">
          <div class="select is-fullwidth" v-else>
            <select name="" v-model="tipoRecibosSelected">
              <option :value="null">Todos los tipos</option>
              <option :value="recibo.id" v-for="(recibo, i) in tiposRecibos" :key="i">{{recibo.nombre}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label for="" class="label">Por estado de recibo</label>
        <div class="control">
          <div class="select is-fullwidth">
            <select name="" v-model="estadoRecibos">
              <option :value="null">Cualquier estado</option>
              <option value="pendiente">Pendiente</option>
              <option value="conformidad">En conformidad</option>
              <option value="disconformidad">En disconformidad</option>
            </select>
          </div>
        </div>
      </div>
      <div class="buttons mt-4">
        <button @click="setDefaults" class="button is-light"><i class="fas fa-broom"></i>&nbsp;Limpiar filtros</button>
        <button @click="updateListado" class="button is-primary" :disabled="errors.count() > 0"><i class="fas fa-search"></i>&nbsp;Buscar</button>
      </div>
    </div>
  </section>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      estadoRecibos: null,
      loadingTiposRecibos: false,
      tiposRecibos: [],
      tipoRecibosSelected: null,
      soloMisRecibos: false,
      userInput: null,
      searchUserResults: [],
      selectedUser: null,
      isFetching: false,
      year: '',
      month: '',
      day: ''
    }
  },
  mounted () {
    this.getTiposRecibos()
  },
  methods: {
    updateListado: function () {
      this.$emit('set-query', this.query)
    },
    setDefaults: function () {
      this.estadoRecibos = null
      this.tipoRecibosSelected = null
      this.searchUserResults = []
      this.selectedUser = null
      this.year = ''
      this.month = ''
      this.day = ''
      this.updateListado()
    },
    getTiposRecibos () {
      this.loadingTiposRecibos = true
      this.$http.get('/tipo-recibo')
        .then(res => {
          this.tiposRecibos = res.data
          this.loadingTiposRecibos = false
        }).catch(err => {
          console.error(err)
          this.$buefy.snackbar.open({
            message: 'Error en la comunicación. Por favor recargue la página',
            type: 'is-warning',
            actionText: 'Recargar',
            indefinite: true,
            onAction: () => {
              location.reload()
            }
          })
        })
    },
    getUser: debounce(
      function () {
        this.isFetching = true
        this.$http
          .request({
            method: 'get',
            url:
              '/organizacion/' +
              this.$store.getters.getUser.organization +
              '/usuario?s=' +
              this.userInput
          })
          .then(response => {
            console.log(response.data)
            this.searchUserResults = response.data.data
            this.isFetching = false
          })
          .catch(error => {
            console.error('Fail', error)
            this.isFetching = false
          })
      },
      // This is the number of milliseconds we wait for the
      // user to stop typing.
      500
    )
  },
  computed: {
    query: function () {
      const obj = {}
      if (this.estadoRecibos != null) {
        obj.estado = this.estadoRecibos
      }
      if (this.tipoRecibosSelected != null) {
        obj.tipo = this.tipoRecibosSelected
      }
      if (this.year !== '') {
        let fecha = ''
        if (this.year !== '') {
          fecha += this.year + '-'
        }
        if (this.month !== '') {
          fecha += this.month + '-'
        } else {
          fecha += '00-'
        }
        if (this.day !== '') {
          fecha += this.day
        } else {
          fecha += '00'
        }
        obj.fecha = fecha
      }
      if (this.selectedUser != null) {
        obj.empleado = this.selectedUser.id
      }
      if (this.soloMisRecibos) {
        obj.empleado = this.$store.getters.getUser.id
      }
      return obj
    }
  },
  watch: {
    year: function (newVal, oldVal) {
      this.month = ''
      this.day = ''
    },
    month: function (newVal, oldVal) {
      this.day = ''
    },
    soloMisRecibos: function (newVal, oldVal) {
      this.selectedUser = null
      this.userInput = null
      this.updateListado()
    }
  }
}
</script>
