<template>
  <nav class="navbar is-primary" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link to="/" class="navbar-item" @click.native="closeMobileNav" exact>
        <img src="/statics/signar-white.svg" alt />
      </router-link>
      <a
        role="button"
        class="navbar-burger has-text-white"
        :class="{'is-active': showMobileNav }"
        @click="toggleMobileNav"
        data-target="navMenu"
        aria-label="menu"
        aria-expanded="false"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{'is-active': showMobileNav }" id="navMenu">
      <div class="navbar-start">
        <router-link
          v-if="$store.getters.hasAnyRole(['user', 'lyris'])"
          to="/repositorio"
          @click.native="closeMobileNav"
          class="navbar-item"
          active-class="is-active"
        >
          <i class="fas fa-folder-open fa-fw fa-lg"></i>&nbsp;Repositorio
        </router-link>
        <router-link
          v-if="$store.getters.hasAnyRole(['empleador', 'empleado'])"
          to="/recibos"
          @click.native="closeMobileNav"
          class="navbar-item"
          active-class="is-active"
        >
          <i class="fas fa-file-invoice-dollar fa-fw fa-lg"></i>&nbsp;Recibos
        </router-link>
        <router-link
          v-if="$store.getters.hasAnyRole(['lyris', 'otros-admin', 'otros-user'])"
          to="/otros-documentos"
          @click.native="closeMobileNav"
          class="navbar-item"
          active-class="is-active"
        >
          <i class="fas fa-file-import fa-fw fa-lg"></i>&nbsp;Notificaciones
        </router-link>
        <router-link
          v-if="$store.getters.hasAnyRole([constants.STRINGS.ROLES.OFICIAL_DE_REGISTRO.ID, constants.STRINGS.ROLES.SUSCRIPTOR.ID, constants.STRINGS.ROLES.ADMINISTRADOR_DE_CERTIFICADOS.ID])"
          :to="{ path: getPathAccordingRol() }"
          @click.native="closeMobileNav"
          class="navbar-item"
          active-class="is-active"
        >
          <i class="fas fa-certificate fa-fw fa-lg"></i>&nbsp;Autoridad Certificante
        </router-link>
        <router-link
          v-if="$store.getters.hasAnyRole(['admin','aspec-admin','serie-admin','auditor'])"
          @click.native="closeMobileNav"
          :to="{ name: 'organizacionInfo', params: {idOrganization: userSession.user.organization}}"
          class="navbar-item"
          active-class="is-active"
        >
          <i class="fas fa-building fa-fw fa-lg"></i>&nbsp;Mi organización
        </router-link>
        <router-link
          :to="{ name: 'adminDashboard'}"
          @click.native="closeMobileNav"
          v-if="hasRole('lyris') || hasRole(constants.STRINGS.ROLES.LYRIS_SOPORTE.ID)"
          class="navbar-item"
          active-class="is-active"
        >
          <i class="fas fa-wrench fa-fw fa-lg"></i>Administración
        </router-link>
        <a @click="openPilaProyectos" class="navbar-item" v-if="pilaNumber">
          <i class="fas fa-file-signature fa-fw fa-lg animated bounceIn"></i>&nbsp;
          <span class="tag is-rounded is-info">{{pilaNumber}}</span>&nbsp;&nbsp;Docs a firmar
        </a>
      </div>
      <div class="navbar-end">
        <article class="b-tag-list is-flex m-0 is-align-items-center is-justify-content-start">
          <b-tag v-if="hasRole(constants.STRINGS.ROLES.LYRIS.ID)" type="is-success is-light" class="navbar-item b-tag m-1 px-3 has-text-weight-medium ml-3" size="is-small" icon="fas fa-user-check">&nbsp;{{constants.STRINGS.ROLES.LYRIS.NAME}}</b-tag>
          <b-tag v-if="hasRole(constants.STRINGS.ROLES.LYRIS_OR.ID)" type="is-primary is-light" class="navbar-item b-tag m-1 px-3 has-text-weight-medium" size="is-small" icon="fas fa-user-check">&nbsp;{{constants.STRINGS.ROLES.LYRIS_OR.NAME}}</b-tag>
          <b-tag v-if="hasRole(constants.STRINGS.ROLES.LYRIS_SOPORTE.ID)" type="is-warning is-light" class="navbar-item b-tag m-1 px-3 has-text-weight-medium" size="is-small" icon="fas fa-user-check">&nbsp;{{constants.STRINGS.ROLES.LYRIS_SOPORTE.NAME}}</b-tag>
        </article>
        <router-link :to="{name: 'customSettings'}" v-if="userSession" class="navbar-item">
          <i class="fas fa-cog fa-lg fa-fw"></i>
          <span class="is-hidden-desktop">&nbsp;Configuración</span>
        </router-link>
        <router-link :to="{path: '/ac/certificados'}" v-if="userSession && userHasCertificate && (userCertificateIsAboutToExpired || userCertificateIsExpired)" class="navbar-item">
         <i class="fas fa-exclamation-triangle fa-lg fa-fw" :class="{'has-text-warning': userCertificateIsAboutToExpired, 'has-text-danger animated flash': userCertificateIsExpired}"></i>
         <span class="is-hidden-desktop">&nbsp;Revise su certificado</span>
        </router-link>
        <router-link :to="{name: 'perfilInformacion'}" v-if="userSession" class="navbar-item">
          <i class="fas fa-user-circle fa-lg fa-fw"></i>&nbsp;
            <span class="is-uppercase"><b>{{userSession.user.surname}}</b></span>,  {{userSession.user.name}}
        </router-link>
        <a @click="logout()" v-if="userSession" class="navbar-item">
          <i class="fas fa-sign-out-alt fa-fw fa-lg"></i>
          <span class="is-hidden-desktop">&nbsp;Cerrar sesión</span>
        </a>
        <router-link to="/login" v-if="!userSession" class="navbar-item">
          <i class="fas fa-sign-in-alt fa-fw fa-lg"></i> Entrar
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import userCertificateExpiration from '@/mixins/userCertificateExpiration'
import constants from '@/constants'
export default {
  mixins: [userCertificateExpiration],
  data () {
    return {
      constants: constants,

      showMobileNav: false
    }
  },
  mounted () {
    const userId = this.userSession.user.id
    this.$http.get(`/usuario/${userId}`).then(response => {
      this.$store.commit('profile/user', response.data)
    })
  },
  methods: {
    toggleMobileNav: function () {
      this.showMobileNav = !this.showMobileNav
    },
    closeMobileNav: function () {
      this.showMobileNav = false
    },
    openPilaProyectos: function () {
      this.$eventHub.$emit('showPila')
    },
    logout: function () {
      this.$router.push({ name: 'logout' })
    },
    hasRole (role) {
      if (this.$store.state.session) {
        return this.$store.state.session.user.roles.includes(role)
      }
      return false
    },
    getPathAccordingRol () {
      if (this.hasRole('suscriptor')) return '/ac/certificados'
      if (this.hasRole('or')) return '/ac/oficial-registro/certificados'
      if (this.hasRole('cert-admin')) return '/ac/administrador-certificados/certificados'
    }
  },
  computed: {
    pilaNumber: function () {
      return this.$store.state.firmador.pila.length
    },
    userSession: function () {
      return this.$store.state.session
    }
  }
}
</script>

<style lang="scss" scoped>
.b-tag-list {
  padding: 0;
  @media screen and (max-width: 1023px){
    padding: 0.5rem 0.75rem;
  }
}
.b-tag {
  @media screen and (max-width: 768px){
    flex-grow: 1;
  }
}
</style>
