<template>
  <div>
    <b-table
      :data="otrosDocumentos"
      :bordered="false"
      :narrowed="false"
      :hoverable="true"
      :loading="isLoading"
      checkable
      :checked-rows="selectedOtrosDocumentos"
      :mobile-cards="true"
      :sortable="true"
      @dblclick="goToDocument"
      @check="checkRow">

      <b-table-column field="nombre" :label="Constants.STRINGS.TABLES.HEADERS.DOCUMENTS.DOCUMENTS" sortable v-slot="props">
        <span class="break-word"><i class="fas fa-file"></i>&nbsp;&nbsp;{{ props.row.nombre }}</span>
      </b-table-column>

      <b-table-column field="otro_documento_usuario" sortable :label="Constants.STRINGS.TABLES.HEADERS.DOCUMENTS.SCOPE" v-slot="props" v-if="this.tieneRolAdminOLyris">
        <b-tooltip v-if="howManyReceivers(props.row) > 1"
          multilined
          :size="howManyReceivers(props.row) > 3 ? 'is-large' : 'is-medium'"
          :label=getMultipleScopeTooltipText(props.row)>
          <b-icon pack="fas" :icon="getMultipleScopeIcon(props.row)"></b-icon>
          {{ getMultipleScopeText(props.row) }}
        </b-tooltip>
        <div v-else>
          <b-icon pack="fas" :icon="getSingleScopeIcon(props.row)"></b-icon>
          {{ getSingleScopeText(props.row) }}
        </div>
      </b-table-column>

      <b-table-column field="estado" :label="Constants.STRINGS.TABLES.HEADERS.DOCUMENTS.SIGN_STATE" sortable centered v-slot="props">
        <div v-if="isAvailableStatus(props.row.estado)">
          {{ Constants.STRINGS.LISTS.NOT_AVAILABLE }}
        </div>
        <div v-else-if="isPendingOrSignedStatus(props.row)">
          <b-icon pack="fas" :icon="getPendingOrSignedStatusIcon(props.row.estado)"></b-icon>{{ getStatusTextForAdmins(props.row) }}
        </div>
        <b-tooltip v-else multilined size="is-large" :label=getEmployeeSignedDocumentTooltipText(props.row.estado)>
          <b-icon pack="far" :icon="getEmployeeSignedIcon(props.row.estado)"></b-icon>{{ getStatusTextForAdmins(props.row) }}
        </b-tooltip>
      </b-table-column>

      <b-table-column field="created_at" :label="Constants.STRINGS.TABLES.HEADERS.DOCUMENTS.DATE" sortable centered v-slot="props">
        {{ new Date(props.row.created_at) | moment('DD/MM/YYYY') }}
      </b-table-column>

      <b-table-column field="tipo_otro_documento.nombre" :label="Constants.STRINGS.TABLES.HEADERS.DOCUMENTS.TYPE" sortable centered v-slot="props">
        {{ props.row.tipo_otro_documento.nombre }}
      </b-table-column>

      <template #empty>
        <div class="is-family-monospace has-text-centered">
          <span v-if="isLoading"></span>
          <span v-else>{{ Constants.STRINGS.LISTS.NO_RESULTS }}</span>
        </div>
      </template>
    </b-table>
    <infinite-loading @infinite="infiniteHandlerDocuments" :identifier="identifierLoaderDocuments">
      <div slot="no-more" class=" py-5 is-size-7 is-family-monospace">
        <span v-if="isLoading">{{ Constants.STRINGS.LOADING.LOADING_TEXT }}</span>
        <span v-else>{{ Constants.STRINGS.LISTS.END_OF_LIST }}</span>
      </div>
      <div slot="no-results" class=" py-5 is-size-7 is-family-monospace">
        <span v-if="isLoading">{{ Constants.STRINGS.LOADING.LOADING_TEXT }}</span>
        <span v-else>{{ Constants.STRINGS.LISTS.END_OF_LIST }}</span>
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import Constants from '../../constants'

export default {
  data () {
    return {
      Constants: Constants,
      isLoading: true,
      otrosDocumentos: [],
      selectedArray: [],
      identifierLoaderDocuments: Math.random(),
      queryDocuments: {
        page: 1,
        nombre: null,
        usuario: null,
        area: null,
        organizacion_completa: null,
        fecha: null,
        estado_documento: null,
        estado_documento_usuario: null,
        tipo: null
      }
    }
  },
  computed: {
    url () {
      return '/otro-documento'
    },
    selectedOtrosDocumentos: function () {
      return this.$store.state.otrosDocumentos.selected
    },
    tieneRolAdminOLyris: function () {
      return this.$store.getters.hasAnyRole([Constants.STRINGS.ROLES.ADMINISTRADOR_DE_OTROS_DOCUMENTOS.ID, Constants.STRINGS.ROLES.LYRIS.ID])
    },
    user: function () {
      return this.$store.getters.getUser
    }
  },
  components: {
    InfiniteLoading
  },
  methods: {
    howManyReceivers (documento) {
      // If I can get at least oneReceiver from permissions, it is for users or areas
      const oneReceiver = this.getOneReceiverOf(documento)
      if (oneReceiver) {
        // If it has 'usuario' attribute set, is for users only, otherwise is for areas
        return oneReceiver.usuario ? documento.otro_documento_usuario.length
          : documento.permisos.filter(permiso => permiso.area && permiso.estado === Constants.STRINGS.PERMISSIONS.STATUS.OTROS_DOCUMENTOS_RECEIVER).length
      }

      // If I can't get oneReceiver from permissions, it is definitely for the uploader
      return 1
    },
    getOneReceiverOf (documento) {
      return documento.permisos.find(permiso => permiso.estado === Constants.STRINGS.PERMISSIONS.STATUS.OTROS_DOCUMENTOS_RECEIVER)
    },
    getMultipleScopeTooltipText (documento) {
      let textResult = ''
      const oneReceiver = this.getOneReceiverOf(documento)

      if (oneReceiver) {
        if (oneReceiver.usuario) {
          documento.otro_documento_usuario.forEach((firma, index) => {
            // if index is 0, then it's the first element, so we don't want to add ,
            if (index !== 0) {
              textResult += ', '
            }
            textResult += firma.usuario.apellido.toUpperCase() + ' ' + firma.usuario.nombre
          })
        }

        if (oneReceiver.area) {
          documento.permisos.filter(permiso =>
            permiso.estado === Constants.STRINGS.PERMISSIONS.STATUS.OTROS_DOCUMENTOS_RECEIVER
          ).forEach((permiso, index) => {
            if (permiso.area) {
              // if index is 0, then it's the first element, so we don't want to add ,
              if (index !== 0) {
                textResult += ', '
              }
              textResult += permiso.area.nombre
            }
          })
        }
      }
      return textResult
    },
    getSingleScopeText (documento) {
      const oneReceiver = this.getOneReceiverOf(documento)
      if (oneReceiver) {
        if (oneReceiver.usuario) {
          return oneReceiver.usuario.apellido.toUpperCase() + ' ' + oneReceiver.usuario.nombre
        }

        if (oneReceiver.area) {
          return oneReceiver.area.nombre
        }

        if (oneReceiver.organizacion) {
          return oneReceiver.organizacion.razon_social
        }
      } else if (this.uploaderIsReceiver(documento)) {
        return documento.administrador.apellido.toUpperCase() + ' ' + documento.administrador.nombre
      }

      return Constants.STRINGS.ERRORS.LIST_OTROS_DOCUMENTOS.CANT_DETERMINE_SCOPE
    },
    getSingleScopeIcon (documento) {
      const oneReceiver = this.getOneReceiverOf(documento)
      if (oneReceiver) {
        if (oneReceiver.usuario) {
          return 'user'
        }

        if (oneReceiver.area) {
          return 'tag'
        }

        if (oneReceiver.organizacion) {
          return 'building'
        }
      } else if (this.uploaderIsReceiver(documento)) {
        return 'user'
      }

      return 'question'
    },
    uploaderIsReceiver (documento) {
      return documento.otro_documento_usuario.find(firma => firma.usuario.id === documento.administrador.id)
    },
    getMultipleScopeText (documento) {
      const howManyReceivers = this.howManyReceivers(documento)
      const oneReceiver = this.getOneReceiverOf(documento)
      if (oneReceiver) {
        if (oneReceiver.usuario) {
          return `Usuarios (${howManyReceivers})`
        }

        if (oneReceiver.area) {
          return `Áreas (${howManyReceivers})`
        }
      }

      return Constants.STRINGS.ERRORS.LIST_OTROS_DOCUMENTOS.CANT_DETERMINE_SCOPE
    },
    getMultipleScopeIcon (documento) {
      const oneReceiver = this.getOneReceiverOf(documento)
      if (oneReceiver) {
        if (oneReceiver.usuario) {
          return 'users'
        }

        if (oneReceiver.area) {
          return 'tags'
        }
      }

      return 'question'
    },
    infiniteHandlerDocuments ($state) {
      this.isLoading = true
      this.$http.get(this.url, { params: this.queryDocuments })
        .then(({ data }) => {
          this.isLoading = false
          if (data.current_page === data.last_page) {
            this.otrosDocumentos.push(...data.data)
            $state.complete()
          }
          if (data.current_page < data.last_page) {
            this.queryDocuments.page += 1
            this.otrosDocumentos.push(...data.data)
            $state.loaded()
          }
        })
    },
    setQuery (query) {
      this.queryDocuments.nombre = query.documentName
      this.queryDocuments.usuario = query.selectedUserId
      this.queryDocuments.area = query.selectedAreaId
      this.queryDocuments.organizacion_completa = query.allOrganization
      this.queryDocuments.fecha = query.date
      this.queryDocuments.tipo = query.selectedDocumentTypeId
      this.queryDocuments.estado_documento = query.selectedDocumentStatus
      this.queryDocuments.estado_documento_usuario = query.selectedDocumentSignStatus
      this.queryDocuments.page = 1
      this.otrosDocumentos = []
      this.identifierLoaderDocuments = Math.random()
    },
    refresh () {
      this.queryDocuments.page = 1
      this.otrosDocumentos = []
      this.$store.commit('otrosDocumentos/setSelected', [])
      this.identifierLoaderDocuments = Math.random()
    },
    goToDocument (documento) {
      this.$router.push('/otro-documento/' + documento.id)
    },
    checkRow: function (checkedDocsArray) {
      this.$store.commit('otrosDocumentos/setSelected', checkedDocsArray)
    },
    getPendingOrSignedStatusIcon: function (status) {
      switch (status) {
        case Constants.STRINGS.DOCUMENTS.STATUS.SIGNED:
          return 'check-circle has-text-success'
        case Constants.STRINGS.DOCUMENTS.STATUS.PENDING_SIGNATURE:
          return 'clock has-text-warning'
        default:
          return 'question'
      }
    },
    getEmployeeSignedIcon: function (status) {
      switch (status) {
        case Constants.STRINGS.DOCUMENTS.STATUS.PENDING_SIGNATURE:
          return 'check-circle has-text-success'
        default:
          return 'question'
      }
    },
    didEmployeeSignedDocument: function (documento) {
      return documento.otro_documento_usuario.find(firma => firma.usuario.id === this.user.id && firma.estado === Constants.STRINGS.DOCUMENTS.STATUS.SIGNED)
    },
    isAvailableStatus: function (status) {
      return (status === Constants.STRINGS.DOCUMENTS.STATUS.AVAILABLE)
    },
    isPendingOrSignedStatus: function (documento) {
      switch (documento.estado) {
        case Constants.STRINGS.DOCUMENTS.STATUS.SIGNED:
          return true
        case Constants.STRINGS.DOCUMENTS.STATUS.PENDING_SIGNATURE:
          return !this.didEmployeeSignedDocument(documento)
        default:
          return false
      }
    },
    getStatusTextForAdmins: function (documento) {
      if (this.tieneRolAdminOLyris) {
        if (documento.estado !== Constants.STRINGS.DOCUMENTS.STATUS.AVAILABLE) {
          return `(${documento.otro_documento_usuario.filter(firma => firma.estado === Constants.STRINGS.DOCUMENTS.STATUS.SIGNED).length}/${documento.otro_documento_usuario.length})`
        }
      }
      return ''
    },
    getEmployeeSignedDocumentTooltipText: function (status) {
      switch (status) {
        case Constants.STRINGS.DOCUMENTS.STATUS.PENDING_SIGNATURE:
          return Constants.STRINGS.TOOLTIPS.LIST_OTROS_DOCUMENTOS.EMPLOYEE_SIGNED_DOCUMENT
        default:
          return Constants.STRINGS.TOOLTIPS.LIST_OTROS_DOCUMENTOS.ERROR_WHEN_GETTING_SIGN_STATUS
      }
    }
  }
}
</script>
