<template>
<div class="box">
  <b-menu :activable="false">
    <b-menu-list label="Mis Certificados" v-if="$store.getters.hasAnyRole(['suscriptor'])">
      <b-menu-item :disabled="!newRequestButtonEnable" v-on:click.native="handleClickEvent" tag="router-link" :to="{name: 'certificadosNuevaSolicitud'}" exact label="Generar certificado"/>
      <b-menu-item tag="router-link" :to="{name: 'certificadosListado'}" exact label="Certificados"/>
    </b-menu-list>
    <b-menu-list label="Oficial de registro" v-if="$store.getters.hasAnyRole(['or'])">
      <b-menu-item tag="router-link" :to="{name: 'certificadosOficialRegistroListado'}" exact label="Solicitudes y certificados"/>
      <b-menu-item tag="router-link" :to="{name: 'oficialesRegistro', params: {idOrganization: organizacion}}" exact label="Oficiales de registro"/>
    </b-menu-list>
    <b-menu-list label="Administrador de certificados" v-if="$store.getters.hasAnyRole(['cert-admin'])">
      <b-menu-item tag="router-link" :to="{name: 'certificadosAdministradorCertificadosListado'}" exact label="Administrar certificados"/>
    </b-menu-list>
  </b-menu>
</div>
</template>
<script>
export default {
  props: ['newRequestButtonEnable'],
  methods: {
    handleClickEvent () {
      if (!this.newRequestButtonEnable) this.openWarningSnackBar()
    },
    openWarningSnackBar () {
      this.$buefy.snackbar.open({
        message: 'No se permite solicitar más de un certificado simultáneamente.',
        type: 'is-danger'
      })
    }
  },
  computed: {
    organizacion: function () {
      return this.$store.state.session.user.organization
    }
  }
}
</script>
