<template>
  <section>
    <div class="box">
      <h1 class="subtitle is-4">Certificados</h1>
      <h1 class="title is-3">Rechazar certificado</h1>
    </div>
    <div class="box is-centered">
      <h1 class="subtitle is-5">Datos de la solicitud</h1>
      <table class="table is-bordered">
        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
        <tbody>
          <tr>
            <th>Nombre y apellido</th>
            <td>{{ nombre }}</td>
          </tr>
          <tr>
            <th>CUIL</th>
            <td>{{ cuil }}</td>
          </tr>
          <tr>
            <th>E-mail</th>
            <td>{{ email }}</td>
          </tr>
          <tr>
            <th>Fecha solicitud</th>
            <td> {{ dateFormat(fechaSolicitud) }}</td>
          </tr>
        </tbody>
      </table>
      <p class="title is-6 mb-3">Razón del rechazo (opcional)</p>
      <b-input v-model="reason" placeholder="Ingresar razón del rechazo" type="textarea" class="mb-5"></b-input>
      <div class="buttons is-right mt-6">
        <b-button class="is-light mr-2" @click="goBack">
          Volver
        </b-button>
        <b-button class="is-primary" @click="refuseCert" :loading="isLoading">
          Continuar
        </b-button>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['idCert'],
  data () {
    return {
      reason: '',
      isLoading: false,
      nombre: null,
      email: null,
      cuil: null,
      fechaSolicitud: null
    }
  },
  beforeMount: function () {
    this.isLoading = true
    this.$http.get(`/certificado/${this.idCert}`)
      .then(response => {
        const certificado = response.data.certificado
        const csr = this.$forge.pki.certificationRequestFromPem(this.$forge.util.decode64(certificado.csr))
        this.nombre = this.$forge.util.decodeUtf8(csr.subject.attributes[1].value)
        this.cuil = this.$forge.util.decodeUtf8(csr.subject.attributes[2].value)
        this.fechaSolicitud = certificado.created_at
        this.email = csr.getAttribute({ name: 'extensionRequest' }).extensions[0].altNames[0].value
      })
      .catch(() => {
        this.$buefy.snackbar.open({
          message: 'Ocurrió un error al recuperar la solicitud del certificado',
          type: 'is-danger'
        })
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    refuseCert: function () {
      this.isLoading = true
      var body = {
        comentario: this.reason
      }
      this.$http.post(`/certificado/${this.idCert}/rechazar`, body)
        .then(() => {
          this.$buefy.snackbar.open({
            message: 'Certificado rechazado exitosamente',
            type: 'is-success'
          })
          this.goBack()
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Ocurrió un error al rechazar el certificado',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    goBack: function () {
      this.$router.go(-1)
    },
    dateFormat: function (date) {
      return new Date(date).toLocaleDateString('fr')
    }
  }
}
</script>
